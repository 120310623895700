import React, { useState } from "react";
import "./components.css";
import ButtonComponent from "../../components/button";
import { Link, useNavigate } from "react-router-dom";
import InputComponent from "../../components/input";

// icons
import { LuArrowUpRight } from "react-icons/lu";
import AlertSmall from "../../components/alert/alertSmall";

const HeaderContainer = ({ title, text, textButton, path, style }) => {
  const navigate = useNavigate();
  return (
    <div className="HeaderContainer" style={style}>
      <h2>{title}</h2>
      <p>{text}</p>
      <div className="ctn-button">
        {textButton && (
          <ButtonComponent text={textButton} onClick={() => navigate(path)} />
        )}
      </div>
    </div>
  );
};

const ProjectContainer = ({ data, style }) => {
  return (
    <div className="ProjectContainer">
      {data.map((item, index) => (
        <React.Fragment key={index}>
          {item.path[2] === "/" ? (
            <Link to={item.path} className="container" style={style}>
              <h4>{item.name}</h4>
              <LuArrowUpRight className="icon" />
            </Link>
          ) : (
            <a href={item.path} className="container" style={style}>
              <h4>{item.name}</h4>
              <LuArrowUpRight className="icon" />
            </a>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setError(false);

    // Ajouter un document à la collection Firestore
    fetch("https://apiv2-6yhl3kiv2a-uc.a.run.app/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        site: "loukaaa.com",
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: description,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
          throw new Error("Network response was not ok");
        }
        return response.json(); // Assure-toi de traiter la réponse JSON
      })
      .then(() => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setDescription("");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error adding form data: ", error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="ContactForm" id="contact">
      <h3>Contactez-nous</h3>
      {error && (
        <AlertSmall type="error">
          Erreur lors de l'envoi du formulaire, veuillez réessayer.
        </AlertSmall>
      )}
      {success && (
        <AlertSmall type="success">
          Votre message a bien été envoyé, nous vous contacterons bientôt.
        </AlertSmall>
      )}
      <div className="ctn-input">
        <InputComponent
          id="firstName"
          placeholder="Prénom"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <InputComponent
          id="lastName"
          placeholder="Nom"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="ctn-input">
        <InputComponent
          id="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="ctn-input">
        <InputComponent
          id="description"
          type="textarea"
          placeholder="Description"
          value={description}
          resize="none"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <p className="text-xxs">
        Vos informations personnelles ne seront jamais partagées avec des tiers.
        Elles seront utilisées uniquement pour vous contacter.
      </p>
      <ButtonComponent
        text="Envoyer"
        loading={loading}
        success={success}
        skin={success}
        onClick={handleSubmit}
      />
    </div>
  );
};

export { HeaderContainer, ProjectContainer, ContactForm };
