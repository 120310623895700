import React from "react";
import "./legal.css";

function Mentions() {
  return (
    <div className="Mentions Legal inner">
      <h1>Mentions légales</h1>
      <p>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site
        loukaaa.com les présentes mentions légales. La connexion et la
        navigation sur le site loukaaa.com par l’Utilisateur impliquent
        acceptation intégrale et sans réserve des présentes mentions légales.
        Ces dernières sont accessibles sur le site à la rubrique « Mentions
        légales ».
      </p>
      <section>
        <h3>ARTICLE 1 : L’éditeur</h3>
        <p>
          L’édition du site loukaaa.com est assurée par Louka, immatriculée sous
          le numéro SIREN 984 438 721 et le numéro SIRET 984 438 721 00016, dont
          le siège social est situé au 4 Rue de la liberté, 38000 Grenoble,
          adresse e-mail : contact@loukaaa.com.
        </p>
      </section>
      <section>
        <h3>ARTICLE 2 : L’hébergeur</h3>
        <p>Le site loukaaa.com est hébergé par Firebase (Google).</p>
        <p>
          L’hébergeur du site est la société Google Cloud EMEA Ltd, dont le
          siège social est situé au 70 Sir John Rogerson’s Quay, Dublin 2,
          Ireland.
        </p>
      </section>
      <section>
        <h3>ARTICLE 3 : L’accès au site</h3>
        <p>
          Le site est accessible par tout endroit, 7j/7, 24h/24, sauf cas de
          force majeure, interruption programmée ou non, pouvant découler d’une
          nécessité de maintenance.
        </p>
        <p>
          En cas de modification, interruption ou suspension des services, le
          site loukaaa.com ne saurait être tenu responsable.
        </p>
      </section>
      <section>
        <h3>ARTICLE 4 : La collecte de données</h3>
        <p>
          Le site loukaaa.com ne collecte pas de cookies ni de données
          personnelles.
        </p>
      </section>
    </div>
  );
}

export default Mentions;
