// InputComponent.js
import React, { useState } from "react";
import "./inputComponent.css";

function InputComponent({
  label,
  type,
  placeholder,
  width,
  onChange,
  children,
  id,
  maxLength,
  value,
  resize,
  disabled,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="InputComponents" style={{ width: width }}>
      <label className="text-sm" onClick={() => setIsActive(true)}>
        {label}
      </label>
      <label
        className={`InputComponents-container ${isActive ? "active" : ""}`}
        onClick={() => setIsActive(true)}
      >
        {children}
        {type === "textarea" ? (
          <textarea
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}
            value={value}
            style={{ resize: resize }}
            onFocus={() => {
              setIsActive(true);
            }}
            onBlur={() => {
              setIsActive(false);
            }}
          />
        ) : (
          <input
            type={type ? type : "text"}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            maxLength={maxLength}
            value={value}
            onFocus={() => {
              setIsActive(true);
            }}
            onBlur={() => {
              setIsActive(false);
            }}
          />
        )}
      </label>
    </div>
  );
}

export default InputComponent;
