// Estimate.jsx
import React, { useEffect, useState } from "react";
import "./estimate.css";

import ButtonComponent from "../../components/button";
import InputComponent from "../../components/input";
import { useNavigate } from "react-router-dom";
import AlertSmall from "../../components/alert/alertSmall";
import { app } from "../../components/firebase/firebase";

import { FaCircleCheck } from "react-icons/fa6";

function Estimate() {
  const db = app.firestore();

  const price = {
    site: {
      showcase: { base: 300, month: 9.99 },
      portfolio: { base: 350, month: 9.99 },
      other: { base: 400, month: 9.99 },
    },
    model: { base: 100, month: 0 },
  };

  const navigate = useNavigate();

  const [step, setStep] = useState(1); // État local pour suivre l'étape actuelle
  const totalSteps = 5; // Nombre total d'étapes du formulaire

  // États locaux pour stocker les valeurs des champs du formulaire
  const [model, setModel] = useState("");
  const [hosting, setHosting] = useState("");
  const [web, setWeb] = useState("");
  const [nameSite, setNameSite] = useState("");
  const [descriptionSite, setDescriptionSite] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Remonter en haut de la page à chaque changement d'étape
  }, [step]);

  const nextStep = () => {
    if (step === 1 && web === "") {
      setError(true);
    } else if (step === 2 && hosting === "") {
      setError(true);
    } else if (step === 3 && model === "") {
      setError(true);
    } else if (step === 4 && (nameSite === "" || descriptionSite === "")) {
      setError(true);
    } else if (
      step === 5 &&
      (firstName === "" || lastName === "" || email === "")
    ) {
      setError(true);
    } else {
      setError(false);
      setStep(step + 1); // Passage à l'étape suivante
    }
  };

  const previousStep = () => {
    setStep(step - 1); // Retour à l'étape précédente
  };

  const handleSubmit = () => {
    setLoading(true);
    setError(false);
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      !regexEmail.test(email)
    ) {
      setError(true);
      setLoading(false);
    } else {
      // Récupérer toutes les valeurs des champs du formulaire
      const formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        site: {
          name: nameSite,
          description: descriptionSite,
          web,
          hosting,
          model,
        },
        date: new Date(),
        userAgent: navigator.userAgent,
        navigator: {
          language: navigator.language,
          platform: navigator.platform,
          vendor: navigator.vendor,
        },
      };

      db.collection("estimates")
        .add(formData)
        .then(() => {
          setLoading(false);
          setStep(100);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setError(true);
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    navigate("/"); // Rediriger l'utilisateur vers la page d'accueil
  };

  return (
    <div className="Estimate">
      <div className="wrapper">
        <header>
          <h3>Estimation</h3>
          <p className="text-sm">
            Répondez à quelques questions pour obtenir un devis personnalisé
          </p>
          {error && (
            <AlertSmall type="error">
              Veuillez remplir tous les champs correctement
            </AlertSmall>
          )}
        </header>
        {step === 1 && (
          <div className="form">
            <input
              type="radio"
              name="web"
              id="web-showcase"
              value="web-showcase" // Ajout de la valeur pour la deuxième radio
              checked={web === "web-showcase"}
              onChange={(e) => setWeb(e.target.value)}
            />
            <label className="container" htmlFor="web-showcase">
              <h5>Site vitrine</h5>
              <p className="text-xs">
                Adapté pour les petites entreprises ou les indépendants qui ont
                besoin d'une présence en ligne.
              </p>
            </label>
            <input
              type="radio"
              name="web"
              id="web-portfolio"
              value="web-portfolio" // Ajout de la valeur pour la première radio
              checked={web === "web-portfolio"}
              onChange={(e) => setWeb(e.target.value)}
            />
            <label className="container" htmlFor="web-portfolio">
              <h5>Site portfolio</h5>
              <p className="text-xs">
                Parfait pour les artistes, les photographes et les créatifs qui
                souhaitent exposer leur travail.
              </p>
            </label>
            <input
              type="radio"
              name="web"
              id="web-other"
              value="web-other" // Ajout de la valeur pour la deuxième radio
              checked={web === "web-other"}
              onChange={(e) => setWeb(e.target.value)}
            />
            <label className="container" htmlFor="web-other">
              <h5>Autre</h5>
              <p className="text-xs">
                Pour tout autre type de site internet, contactez-nous pour un
                devis personnalisé.
              </p>
            </label>
          </div>
        )}
        {step === 2 && (
          <div className="form">
            <input
              type="radio"
              name="form-hosting"
              id="hosting"
              value="true" // Ajout de la valeur pour la deuxième radio
              checked={hosting === "true"}
              onChange={(e) => setHosting(e.target.value)}
            />
            <label className="container" htmlFor="hosting">
              <h5>Hébergement</h5>
              <p className="text-xs">
                Héberger toutes vos sites internet facilement et automatiquement
              </p>
            </label>
            <input
              type="radio"
              name="form-hosting"
              id="no-hosting"
              value="false" // Ajout de la valeur pour la première radio
              checked={hosting === "false"}
              onChange={(e) => setHosting(e.target.value)}
            />
            <label className="container" htmlFor="no-hosting">
              <h5>Sans</h5>
              <p className="text-xs">
                Vous recevrez les fichiers de votre site internet pour pouvoir
                l'héberger
              </p>
            </label>
          </div>
        )}
        {step === 3 && (
          <div className="form">
            <input
              type="radio"
              name="form-model"
              id="model"
              value="true" // Ajout de la valeur pour la deuxième radio
              checked={model === "true"}
              onChange={(e) => setModel(e.target.value)}
            />
            <label className="container" htmlFor="model">
              <h5>Maquette</h5>
              <p className="text-xs">
                Si vous avez une maquette Figma, Adobe XD, Canva ou autres. Pour
                la création de votre site internet
              </p>
            </label>
            <input
              type="radio"
              name="form-model"
              id="no-model"
              value="false" // Ajout de la valeur pour la première radio
              checked={model === "false"}
              onChange={(e) => setModel(e.target.value)}
            />
            <label className="container" htmlFor="no-model">
              <h5>Sans</h5>
              <p className="text-xs">
                Vous n'avez pas de maquette, nous vous proposerons un design
                personnalisé
              </p>
            </label>
          </div>
        )}
        {step === 4 && (
          <div className="form">
            <div className="ctn-input">
              <InputComponent
                id="nameSite"
                placeholder="Nom du site"
                value={nameSite}
                onChange={(e) => setNameSite(e.target.value)}
              />
            </div>
            <InputComponent
              id="descriptionSite"
              placeholder="Description"
              type={"textarea"}
              value={descriptionSite}
              onChange={(e) => setDescriptionSite(e.target.value)}
            />
          </div>
        )}
        {step === 5 && (
          <div className="form">
            <AlertSmall type="info">
              Nous vous recontacterons dans les plus brefs délais pour finaliser
              votre devis.
            </AlertSmall>
            <h5 className="price">
              <p>
                À partir de{" "}
                <span>
                  {(web === "web-showcase"
                    ? price.site.showcase.base
                    : web === "web-portfolio"
                    ? price.site.portfolio.base
                    : price.site.other.base) +
                    (model === "true" && price.model.base)}
                </span>
                €
                {hosting === "true" && (
                  <div>
                    {" et "}
                    <span>
                      {web === "web-showcase"
                        ? price.site.showcase.month
                        : web === "web-portfolio"
                        ? price.site.portfolio.month
                        : price.site.other.month}
                    </span>
                    {"€/mois"}
                  </div>
                )}
              </p>
            </h5>
            <div className="ctn-input">
              <InputComponent
                id="firstName"
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <InputComponent
                id="lastName"
                placeholder="Nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="ctn-input">
              <InputComponent
                id="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <p className="text-xxs">
              Vos informations personnelles ne seront jamais partagées avec des
              tiers. Elles seront utilisées uniquement pour vous contacter dans
              le cadre de votre devis.
            </p>
          </div>
        )}
        {step === 100 && (
          <div className="success">
            <p>
              <FaCircleCheck className="icon" />
              Votre devis a bien été envoyé, nous vous recontacterons dans les
              plus brefs délais.
            </p>
          </div>
        )}
        <div className="ctn-button">
          {step !== 100 && (
            <ButtonComponent
              text={step === 1 ? "Annuler" : "Retour"}
              iconRight={step === 1 ? false : true}
              skin="secondy"
              onClick={step === 1 ? handleCancel : previousStep}
            />
          )}
          {step !== totalSteps && (
            <ButtonComponent
              text={step === 100 ? "Accueil" : "Suivant"}
              iconLeft={step === 100 ? false : true}
              onClick={step === 100 ? () => navigate("/") : nextStep}
            />
          )}
          {step === totalSteps && (
            <ButtonComponent
              text="Terminer"
              onClick={handleSubmit}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Estimate;
