/* ================================= scrollTo ================================= */

export const scrollTo = (id) => {
  const offset = 100;
  const element = document.getElementById(id);
  const position = element.offsetTop - offset;
  window.scrollTo({ top: position, behavior: "smooth" });
};

/* ============================================================================ */

/* ================================ formatTime ================================ */
// seconds = mm:ss or hh:mm:ss

export const formatTime = (seconds) => {
  seconds = parseInt(seconds);
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedMins = mins < 10 ? `0${mins}` : mins;
  const formattedSecs = secs < 10 ? `0${secs}` : secs;

  if (hrs > 0) {
    const formattedHrs = hrs < 10 ? `0${hrs}` : hrs;
    return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
  } else {
    return `${formattedMins}:${formattedSecs}`;
  }
};

/* ============================================================================ */

/* ============================ getYouTubeVideoId ============================= */
// url video youtube = code video youtube

export const getYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

/* ============================================================================ */

/* ============================== handleCopyText ============================== */
// text to clipboard

export const handleCopyText = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      return true;
    })
    .catch((__) => {
      return false;
    });
};

/* ============================================================================ */

/* =============================== validateUrl ================================ */
// valide url return true or false

export const validateUrl = (input) => {
  if (!input) {
    return false;
  }
  try {
    const parsedUrl = new URL(input);

    if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
      return false;
    }

    if (parsedUrl.hostname === "url.loukaaa.com") {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};
