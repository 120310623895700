import React from "react";
import "./legal.css";

function Cookies() {
  return (
    <div className="Cookies Legal inner">
      <h1>Cookies</h1>
      <p>
        Lors de votre navigation sur le site loukaaa.com, des cookies peuvent
        être utilisés pour assurer le bon fonctionnement du site. Ces cookies
        sont strictement nécessaires pour vous offrir une expérience optimale
        lors de votre visite.
      </p>
      <section>
        <h3>Quels utilisons-nous ?</h3>
        <p>
          Nous n’utilisons que des cookies nécessaires au bon fonctionnement de
          notre site. Ces cookies permettent de :
        </p>
        <ul>
          <li>Assurer la sécurité et la performance du site.</li>
          <li>Permettre la navigation fluide entre les pages.</li>
        </ul>
        <p>
          Aucun cookie personnel ou de suivi de vos activités n’est récupéré ni
          stocké par loukaaa.com. Ces cookies ne collectent pas d’informations
          permettant de vous identifier personnellement.
        </p>
      </section>
      <section>
        <h3>Désactivation des cookies</h3>
        <p>Le site loukaaa.com est hébergé par Firebase (Google).</p>
        <p>
          L’hébergeur du site est la société Google Cloud EMEA Ltd, dont le
          siège social est situé au 70 Sir John Rogerson’s Quay, Dublin 2,
          Ireland.
        </p>
      </section>
    </div>
  );
}

export default Cookies;
